import React, { useContext, useReducer } from "react";
import { logout, getProfile } from "../_services/user";
import { getAuthHeader } from "../_helper/header";

const FETCH_PROFILE = "FETCH_PROFILE";
const UPDATE_USER = "UPDATE_USER";

const initialState = {
  authStatus: false,
  messageEmail: 0,
  messageText: 0,
  reminderEmail: 0,
  reminderText: 0,
  airlineEmail: "",
  airlineEmailVerification: 0,
  appleAuthentication: 0,
  avatar: "",
  countryCode: "",
  crewType: "",
  depositVerification: 0,
  dob: "",
  facebookAuthentication: 0,
  googleAuthentication: 0,
  id: "",
  idVerificationStatus: 0,
  name: "",
  payoutVerification: 0,
  personalEmail: "",
  personalEmailVerification: 0,
  phone: "",
  phoneVerification: 0,
  type: "Crewmember",
};

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_PROFILE: {
      const newState = { ...state, authStatus: true, ...action.payload };

      return newState;
    }
    case UPDATE_USER: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const Context = React.createContext();

export const useAuth = () => {
  return useContext(Context);
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchUser = async () => {
    try {
      const res = await getProfile();
      if (res.data.status) {
        return dispatch({ type: FETCH_PROFILE, payload: res.data.data });
      }
    } catch (error) {
      logout();
    }
  };

  const updateUser = (data) => {
    dispatch({ type: UPDATE_USER, payload: data });
  };

  return (
    <Context.Provider value={{ user: state, fetchUser, updateUser }}>
      {children}
    </Context.Provider>
  );
};
