import { AppConfig } from '../Config'

export const constant = {
    TOKEN_KEY: 'cut',
    DATA_KEY: 'cud',
    RESET_TOKEN_KEY: 'rst',
    REGISTER_USER_DATA: 'rrd',
    USER_REGISTER_REQUEST: 'ddw',
    RESET_REQUEST_EMAIL: 'erd',
    RESET_REQUEST_PHONE: 'erx',
    EMAIL: 'esdf-fddf',
    MOBILE: 'shjh-fdd',
    BASIC_AUTH: {
        USERNAME: 'dummy',
        PASSWORD: 'dummy@12345',
    },
    googleAPI_KEY: AppConfig.googleAPI_KEY,
    GOOGLE_CLIENTID: AppConfig.GOOGLE_CLIENTID,
    FACEBOOK_APPID: AppConfig.FACEBOOK_APPID,
    TEMP_REF: 'tempRef',

    // ------------ Local -------------------------//
    // apiUrl: "http://localhost:4500/api/v1",
    // HOST_WEB_URL: "http://localhost:5042/home",
    // mediaUrl: "http://localhost:4500/media/",
    // persona_mode: "sandbox",
    // persona_template_id: "itmpl_Ygs16MKTkA6obnF8C3Rb17dm",

    // development server - newly created on 22-08-2023
    apiUrl: AppConfig.apiUrl,
    HOST_WEB_URL: AppConfig.HOST_WEB_URL,
    mediaUrl: AppConfig.mediaUrl,
    persona_mode: 'sandbox',
    persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm',

    // development server - newly created on 11-01-2022
    //  apiUrl: "http://35.90.236.251:4500/api/v1",
    //  HOST_WEB_URL: "http://35.90.236.251:4300/home",
    //  mediaUrl: "http://35.90.236.251:4500/media/",
    //  persona_mode: "sandbox",
    //  persona_template_id: "itmpl_Ygs16MKTkA6obnF8C3Rb17dm",

    // // ------------ biz4 testing  -------------------------//
    // apiUrl: "https://747d-182-78-194-242.ngrok.io/api/v1",
    // // apiUrl: "http://541e-182-78-194-242.ngrok.io",
    // HOST_WEB_URL: "http://localhost:5042/home",
    // mediaUrl: "http://35.85.155.38:4500/media/",
    // persona_mode: "sandbox",
    // persona_template_id: "itmpl_Ygs16MKTkA6obnF8C3Rb17dm",

    // ------------ staging -------------------------//
    // apiUrl: "http://35.85.155.38:4500/api/v1",
    // HOST_WEB_URL: "http://35.85.155.38:4300/home",
    // mediaUrl: "http://35.85.155.38:4500/media/",
    // persona_mode: "sandbox",
    // persona_template_id: "itmpl_Ygs16MKTkA6obnF8C3Rb17dm",

    // ------------ production -------------------------//
    // apiUrl: "https://api.crewmatesapp.com/api/v1",
    // HOST_WEB_URL: "https://host.crewmatesapp.com/home",
    // mediaUrl: "https://host.crewmatesapp.com/media/",
    // persona_mode: "production",
    // persona_template_id: "itmpl_dfRJkeBSrdtJL925ehrPSC8t",
}
