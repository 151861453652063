import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider as AuthProvider } from './context/authContext'
import { Provider as DashboardProvider } from './context/dashboardContext'
import { Provider as BookingProvider } from './context/bookingContext'
import { Provider as PropertyFilterProvider } from './context/propertyFilterContext'
import { constant } from './_config/constant'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'

import './styles/main.scss'

// Sentry.init({
//     dsn: 'https://9eed3571f82716ac36a65669894dea30@o4507683481649152.ingest.us.sentry.io/4507694996324352',
//     integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.replayIntegration()],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', /^https:\/\/crewmatesapp\.com/, /^https:\/\/dev.crewmatesapp\.com/],
//     // Profiling
//     profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

ReactDOM.render(
    // <React.StrictMode>
    <GoogleOAuthProvider clientId={constant.GOOGLE_CLIENTID}>
        <AuthProvider>
            <DashboardProvider>
                <BookingProvider>
                    <PropertyFilterProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </PropertyFilterProvider>
                </BookingProvider>
            </DashboardProvider>
        </AuthProvider>
    </GoogleOAuthProvider>,
    // </React.StrictMode>,
    document.getElementById('root')
)
