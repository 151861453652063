import React, { useReducer, useContext } from "react";
// import { logout, getProfile } from "../_services/user";

const TOGGLE = "TOGGLE";
const HIDE = "HIDE";

const initialState = {
  sidebarState: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE: {
      return { ...state, sidebarState: !state.sidebarState };
    }
    case HIDE: {
      return { ...state, sidebarState: false };
    }
    default:
      return state;
  }
};

export const Context = React.createContext();
export const useSidebarContext = () => {
  return useContext(Context);
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleDashboard = (data) => {
    dispatch({ type: TOGGLE });
  };

  const hideSidebar = () => {
    dispatch({ type: HIDE });
  };

  return (
    <Context.Provider value={{ toggleDashboard, hideSidebar, ...state }}>
      {children}
    </Context.Provider>
  );
};
