import React, { useContext, useReducer } from "react";

const UPDATE_FILTER = "UPDATE_FILTER";

const initialState = {
  listing_type: [],
  property_type: [],
  profession: [],
  amenities: [],
  latlng: [],
  gender: [],
  price: [0, 1001],
  stayLimit: [1, 30],
  radiusToAirport: [0, 50],
};

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FILTER: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const Context = React.createContext();

export const usePropertyFilter = () => {
  return useContext(Context);
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateFilter = (options) => {
    dispatch({ type: UPDATE_FILTER, payload: options });
  };

  return (
    <Context.Provider value={{ property_filter: state, updateFilter }}>
      {children}
    </Context.Provider>
  );
};
