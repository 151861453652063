import React, { useReducer } from "react";

const CREATE_BOOKING = "CREATE_BOOKING";

const initialState = {
  id: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const Context = React.createContext();

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const createBooking = (data) => {
    dispatch({ type: CREATE_BOOKING, payload: data });
  };

  return (
    <Context.Provider value={{ user: state, createBooking }}>
      {children}
    </Context.Provider>
  );
};
